import React from 'react';
import {
    faCog,
    faCancel,
    faMailBulk,
    faHeart,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components/common.tsx';
import LinkTile from '../../components/LinkTile.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { useAudio } from '../../contexts/AudioContext.tsx';

function AccountPage() {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const { stopAudio } = useAudio();
    const handleLogout = () => {
        logout().then(() => {
            stopAudio();
            navigate(routeNames.login);
        });
    };

    return (
        <Page>
            <h1 className="pb-2">Account</h1>
            {[
                {
                    link: routeNames.account_settings,
                    text: 'Manage Account',
                    icon: faCog,
                },
                {
                    link: routeNames.account_interests,
                    text: 'Interests',
                    icon: faHeart,
                },
                {
                    text: 'Contact Us',
                    icon: faMailBulk,
                    link: routeNames.account_contact,
                },
                {
                    onClick: handleLogout,
                    text: 'Logout',
                    icon: faCancel,
                },
            ].map((item) => (
                <div key={item.text} className="pb-2">
                    <LinkTile
                        key={item.text}
                        link={item.link}
                        text={item.text}
                        icon={item.icon}
                        onClick={item.onClick}
                    />
                </div>
            ))}
        </Page>
    );
}

export default AccountPage;
