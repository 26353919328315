import React, { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { routeNames } from '../navigation/types.navigation.ts';

type LinkTileProps = {
    text: string;
    icon: IconProp;
    link?: routeNames;
    onClick?: () => void;
};

function LinkTile(props: LinkTileProps) {
    const { icon, text, link, onClick } = props;

    const content = (
        <div className="p-2 bg-gray-100 flex rounded">
            <div className="rounded h-12 w-12 flex justify-center items-center bg-white mr-2">
                <FontAwesomeIcon icon={icon} size="xl" />
            </div>
            <div className="flex justify-between items-center flex-1">
                <label>{text}</label>
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        </div>
    );

    function onClickHandler(
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        if (onClick) {
            e.preventDefault();
            onClick();
        }
    }

    return (
        <Link to={link || '#'} onClick={(e) => onClickHandler(e)}>
            {content}
        </Link>
    );
}

export default LinkTile;
