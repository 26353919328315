import React, { Component, ReactNode } from 'react';
import { Button } from '../common.tsx';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        // Update state so the next render shows the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        // You can also log the error to an error reporting service
        console.error('Error caught in ErrorBoundary: ', error, errorInfo);
    }

    render() {
        const { state, props } = this;
        if (state.hasError) {
            // Fallback UI for when an error occurs
            return (
                <div className="flex items-center justify-center text-center h-full">
                    <div className="w-11/12 max-w-lg ">
                        <img
                            src="/images/blob_142.png"
                            alt="catus"
                            className="max-w-48 mx-auto"
                        />
                        <h1 className="mb-2 text-5xl">
                            Oops Something went wrong!
                        </h1>
                        <p className="mb-2 text-lg leading-5">
                            We are in early access this might happen from time
                            to time, please have patience while we fix it
                        </p>
                        <div className="mb-2">
                            <Button
                                onClick={() => {
                                    window.location.href = '/';
                                }}
                            >
                                Refresh
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        // Render children if no error has occurred
        return props.children;
    }
}

export default ErrorBoundary;
