/* eslint-disable import/prefer-default-export */
import initSqlJs, { Database } from 'sql.js';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import sqlWasm from '!!file-loader?name=sql-wasm-[contenthash].wasm!sql.js/dist/sql-wasm.wasm';

export class SQLiteService {
    // eslint-disable-next-line no-use-before-define
    private static instance: SQLiteService;

    private db: Database | null = null;

    // Public static method to access the singleton instance
    public static async getInstance(): Promise<SQLiteService> {
        if (!SQLiteService.instance) {
            SQLiteService.instance = new SQLiteService();
            await SQLiteService.instance.loadDatabase();
        }
        return SQLiteService.instance;
    }

    // Load the database from file (only once)
    private async loadDatabase(): Promise<void> {
        const SQL = await initSqlJs({ locateFile: () => sqlWasm });
        const response = await fetch('/sql/data.db');
        const buffer = await response.arrayBuffer();
        this.db = new SQL.Database(new Uint8Array(buffer));
    }

    // Run a SQL query and return results
    public query(sql: string, params: any[] = []): any[] {
        if (!this.db) {
            throw new Error('Database not initialized');
        }

        const result = this.db.exec(sql, params);
        if (result.length > 0) {
            const { columns } = result[0];
            const { values } = result[0];
            return values.map((row) => {
                const obj: { [key: string]: any } = {};
                columns.forEach((col, i) => {
                    obj[col] = row[i];
                });
                return obj;
            });
        }
        return [];
    }
}

export const SQLiteServiceInstance = SQLiteService.getInstance();
