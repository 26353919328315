import { useEffect, useState } from 'react';
import { SQLiteService } from './db.content.ts';
import { Interest, Tables } from './types.content.ts';

export async function getAllInterests(): Promise<Interest[]> {
    const dbService = await SQLiteService.getInstance();
    const result = dbService.query(`SELECT * FROM ${Tables.interests}`);
    return result as Interest[];
}

export function useGetAllInterests(): {
    loading: boolean;
    data: Interest[];
} {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Interest[]>([]);
    useEffect(() => {
        async function setup() {
            const interests = await getAllInterests();
            setData(interests);
            setLoading(false);
        }
        setup();
    }, []);
    return { loading, data };
}

export async function getInterestById(id: number): Promise<Interest | null> {
    const dbService = await SQLiteService.getInstance();
    const result = dbService.query(
        `SELECT * FROM ${Tables.interests} WHERE id = ?`,
        [id]
    );
    return result.length > 0 ? (result[0] as Interest) : null;
}
