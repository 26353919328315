import React from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Page } from '../../components/common.tsx';
import { ContentInfoList } from '../../components/ContentInfoItem.tsx';
import { onToContent } from '../../utils/navigate.helpers.ts';
import { useGetListsWithContentsByCategoryId } from '../../content/lists.query.content.ts';
import { useGetCategoryById } from '../../content/category.query.content.ts';
import LoadingOverlay from '../../components/LoadingOverlay.tsx';

function CategoryPage() {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const {
        data: category,
        error: categoryError,
        loading: categoryLoading,
    } = useGetCategoryById(Number(id));

    const { data: lists, loading: listsLoading } =
        useGetListsWithContentsByCategoryId(Number(id));

    if (categoryLoading) {
        return <LoadingOverlay isLoading />;
    }

    if (categoryError || !category) {
        navigate('/nook');
        alert('Category not found');
        return null;
    }

    return (
        <Page>
            <div className="flex flex-row items-center pb-2">
                <Link
                    to=".."
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}
                    className="mr-2"
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                </Link>
                <h1>{category?.title}</h1>
            </div>
            {listsLoading ? (
                <p>....Loading</p>
            ) : (
                lists.map((list) => (
                    <div key={list.contentListId} className="pb-4">
                        <ContentInfoList
                            list={list}
                            onToHandler={onToContent}
                        />
                    </div>
                ))
            )}
        </Page>
    );
}

export default CategoryPage;
