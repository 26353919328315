import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { captureException, withScope } from '@sentry/react';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { InterestList } from './OnboardPage.tsx';
import { setUserDataInterests } from '../../queries/users.query.ts';
import { Page, Button } from '../../components/common.tsx';

function SelectedInterestsPage() {
    const { userData, user, fetchUserData } = useAuth();

    const [selectedInterests, setSelectedInterests] = useState<number[]>([]);

    const navigate = useNavigate();

    const onSaveHandler = async () => {
        if (!user) return;
        try {
            await setUserDataInterests(user?.uid, selectedInterests);
            await fetchUserData();
            navigate(-1);
        } catch (e) {
            withScope((scope) => {
                scope.setExtra('uid', userData?.id);
                scope.setExtra('action', 'save_interests');
                captureException(e);
            });
        }
    };

    useEffect(() => {
        setSelectedInterests(userData?.interests || []);
    }, [userData]);
    return (
        <Page>
            <div className="flex flex-1 flex-col h-full">
                <div className="flex pb-2">
                    <Link
                        to=".."
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }}
                        className="mr-2"
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="text-2xl"
                        />
                    </Link>
                    <h1>Selected Intertests</h1>
                </div>
                <InterestList
                    selectedInterests={selectedInterests}
                    setSelectedInterests={setSelectedInterests}
                />
                <div className="pt-2 flex flex-1 items-end ">
                    <div className="w-full">
                        <Button onClick={onSaveHandler}>Save</Button>
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default SelectedInterestsPage;
