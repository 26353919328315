import {
    CaretRightOutlined,
    // HeartOutlined,
    PauseOutlined,
    LoadingOutlined,
    HeartOutlined,
    HeartFilled,
} from '@ant-design/icons';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { captureException, withScope } from '@sentry/react';
import { capitalizeFirstLetter } from '../../utils/string.helpers.ts';
import { useAudio } from '../../contexts/AudioContext.tsx';
import { useGetContentById } from '../../content/contents.query.content.ts';
import LoadingOverlay from '../../components/LoadingOverlay.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';
import { useAuth } from '../../contexts/AuthContext.tsx';
import {
    addContentToFavourites,
    removeContentToFavourites,
} from '../../queries/users.query.ts';

function PlayPage() {
    const { id } = useParams<{ id: string }>();
    const { userData, fetchUserData } = useAuth();
    const [isFavourited, setIsFavourited] = useState<boolean>(
        userData?.favouritedContent.includes(parseInt(id || '', 10)) || false
    );
    const navigate = useNavigate();

    const { isPlaying, pauseAudio, playAudio, audioLoading, currentAudioUrl } =
        useAudio();
    const { data: content, loading, error } = useGetContentById(Number(id));

    const onAudioClickHandler = () => {
        if (content) {
            if (isPlaying && currentAudioUrl === content.audio) {
                pauseAudio();
            } else {
                playAudio(content);
            }
        }
    };

    if (loading) {
        return <LoadingOverlay isLoading />;
    }

    if (!content || error) {
        navigate(routeNames.nook);
        alert('Content not found');
        return null;
    }

    const onFavouriteHandler = async (favouriteFlag: boolean) => {
        setIsFavourited(!favouriteFlag);
        try {
            if (userData?.id && id) {
                if (favouriteFlag) {
                    await removeContentToFavourites(
                        userData?.id,
                        parseInt(id, 10)
                    );
                } else {
                    await addContentToFavourites(
                        userData?.id,
                        parseInt(id, 10)
                    );
                }
                await fetchUserData();
            }
        } catch (e) {
            withScope((scope) => {
                scope.setExtra('uid', userData?.id);
                scope.setExtra('action', 'load_content_on_nook_page');
                captureException(e);
            });
        }
    };

    return (
        <div className="bg-gradient-to-r from-sky-100 via-sky-200 to-sky-400 bg-[length:400%_400%] animate-gradient bg-gradient-45 h-full w-full p-4">
            <div className="flex flex-row justify-between">
                <Link
                    to=".."
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}
                    className="mr-2"
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                </Link>
                <button onClick={() => onFavouriteHandler(isFavourited)}>
                    {isFavourited ? (
                        <HeartFilled className="text-2xl" />
                    ) : (
                        <HeartOutlined className="text-2xl" />
                    )}
                </button>
            </div>
            <div className="flex h-full items-center justify-center flex-col w-full">
                <h3>{content.title}</h3>
                <label className="font-normal pb-2">
                    {capitalizeFirstLetter(content.type)}
                </label>
                <button
                    onClick={onAudioClickHandler}
                    className="bg-black rounded-full h-28 w-28 flex justify-center items-center"
                >
                    {(() => {
                        if (audioLoading) {
                            return (
                                <LoadingOutlined className="text-white text-7xl" />
                            );
                        }
                        if (isPlaying && currentAudioUrl === content.audio) {
                            return (
                                <PauseOutlined className="text-white text-7xl" />
                            );
                        }
                        return (
                            <CaretRightOutlined className="text-white text-7xl" />
                        );
                    })()}
                </button>
            </div>
        </div>
    );
}

export default PlayPage;
