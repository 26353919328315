import React, { useEffect, useState } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useNavigate } from 'react-router-dom';
import { captureException, withScope } from '@sentry/react';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { setUserDataHasSubscribed } from '../../queries/users.query.ts';
import LoadingOverlay from '../../components/LoadingOverlay.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';

type SubscriptionData = {
    planId: string;
    title: string;
};

function getSubscriptionData(subscriptionCode: string): SubscriptionData {
    switch (subscriptionCode) {
        case 'monthly':
            return {
                planId: process.env.REACT_APP_PAYPAL_MONTHLY_PLAN_ID || '',
                title: 'Monthly Premium Plan',
            };
        default:
            return {
                planId: process.env.REACT_APP_PAYPAL_MONTHLY_PLAN_ID || '',
                title: 'Monthly Premium Plan',
            };
    }
}

function SubscriptionPage() {
    const navigate = useNavigate();
    const [subscriptionData, setSubscriptionData] = useState<SubscriptionData>({
        planId: '',
        title: '',
    });

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            const storedCode = await localStorage.getItem('subscriptionCode');
            const data = getSubscriptionData(storedCode || 'monthly');
            setSubscriptionData(data);
        };

        fetchSubscriptionData();
    }, []);

    const { user, userData } = useAuth();

    const handlePaymentSuccess = async () => {
        try {
            await setUserDataHasSubscribed(user?.uid);

            if (userData?.isOnboarded) {
                navigate(routeNames.nook);
            } else {
                navigate(routeNames.onboard);
            }
        } catch (e) {
            withScope((scope) => {
                scope.setExtra('uid', user?.uid);
                scope.setExtra('action', 'change_password');
                captureException(e);
            });
        }
    };

    if (subscriptionData.planId === '') {
        return <LoadingOverlay isLoading />;
    }

    return (
        <div className="flex h-full   ">
            <div className="md:w-5/12 w-full bg-gray-100  p-3 h-full ">
                <div className=" w-full bg-white rounded-lg shadow-lg p-4  h-full flex items-center justify-center pt-0">
                    <div>
                        <img
                            src="/images/blob_1.png"
                            alt="abstract blob"
                            className="w-6/12 mx-auto max-w-32"
                        />
                        <h1 className="text-3xl font-bold mb-2 text-center">
                            Lifetime Access to Nook for $4.99
                        </h1>
                        <p className="text-gray-500 text-center pb-4">
                            Receive 3 day trial with no charge, cancel anytime
                        </p>

                        <div className="bg-blue-50 p-4 rounded-md mb-6">
                            <h2 className="text-xl font-semibold text-blue-900">
                                Why Go Premium?
                            </h2>
                            <ul className="list-disc pl-5 mt-2 text-blue-800">
                                <li>Access exclusive to all content.</li>
                                <li>Personalized mindfulness programs.</li>
                                <li>Early access to new content & features.</li>
                                <li>Ad-free experience.</li>
                            </ul>
                        </div>

                        <PayPalButtons
                            createSubscription={(data, actions) => {
                                return actions.subscription.create({
                                    plan_id: subscriptionData.planId, // Plan ID from PayPal dashboard
                                    custom_id: user?.uid,
                                });
                            }}
                            onApprove={async (data, actions) => {
                                if (actions.subscription) {
                                    return actions.subscription
                                        .get()
                                        .then((details) => {
                                            handlePaymentSuccess();
                                        });
                                }

                                return console.log('Subscription Error');
                            }}
                            onError={(err) => {
                                console.error(
                                    'PayPal Subscription Error:',
                                    err
                                );
                            }}
                        />
                        <p className="text-sm text-gray-500 mt-3 text-center">
                            By starting your free trial, you agree to our{' '}
                            <a
                                href="/terms"
                                className="text-blue-500 hover:underline"
                            >
                                Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href="/privacy"
                                className="text-blue-500 hover:underline"
                            >
                                Privacy Policy
                            </a>
                            .
                        </p>
                        <p className="text-sm text-gray-500 mt-3 text-center">
                            Paypal Payment Options not being displayed? Please
                            Refresh page.
                        </p>
                    </div>
                </div>
            </div>
            <div
                className="w-7/12 max-md:hidden p-20 h-full flex flex-col justify-end"
                style={{ backgroundColor: '#8369FF' }}
            >
                <img
                    src="/images/abstract_bg.png"
                    alt="Background"
                    className="max-w-md w-8/12"
                />
                <div>
                    <h1 className="text-6xl text-white pb-4">
                        You&apos;re so close!
                    </h1>
                    <h2 className="pb-4 text-white">
                        Setup payment now to get full access to all our wellness
                        content
                    </h2>
                    <p className="text-white">
                        You can cancel anytime in the 3 days by navigating to
                        account {'->'} manage account {'->'} cancel subscription
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionPage;
