import React, {
    createContext,
    useState,
    useContext,
    PropsWithChildren,
} from 'react';
import { routeNames } from '../navigation/types.navigation.ts';
import { Button } from '../components/common.tsx';
import router from '../navigation/Router.tsx';

export enum ModalType {
    GET_PREMIUM = 'GET_PREMIUM',
}
interface ModalContextProps {
    showModal: (type: ModalType) => void;
    hideModal: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

type ModalContent = {
    title: string;
    message: string;
    buttonLabel: string;
    onClick: () => void;
    hasClose?: boolean;
};

function getModalContent(modalType: ModalType): ModalContent {
    switch (modalType) {
        case ModalType.GET_PREMIUM:
            return {
                title: 'Get Premium',
                message: 'Upgrade to premium to access this content',
                buttonLabel: 'Upgrade',
                onClick: () => {
                    // navigate without hook
                    router.navigate(routeNames.subscription);
                },
                hasClose: true,
            };
        default:
            return {
                title: '',
                message: '',
                buttonLabel: '',
                onClick: () => {
                    console.log('default onClick');
                },
            };
    }
}

type ModalProps = {
    modalType: ModalType;
    hideModal: () => void;
};
function Modal(props: ModalProps) {
    const { modalType, hideModal } = props;

    const { buttonLabel, message, onClick, title, hasClose } =
        getModalContent(modalType);
    return (
        <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
            <div className="fixed inset-0 flex justify-center items-center z-50">
                <div className="bg-white p-4 rounded-lg">
                    <h2 className="text-xl font-bold">{title}</h2>
                    <p>{message}</p>
                    <Button
                        onClick={() => {
                            onClick();
                            hideModal();
                        }}
                    >
                        {buttonLabel}
                    </Button>
                    {hasClose && (
                        <div className="pt-2">
                            <Button onClick={hideModal}>Close</Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export function ModalProvider({ children }: PropsWithChildren) {
    const [modalType, setModalType] = useState<ModalType | null>(null);

    const showModal = React.useCallback((type: ModalType) => {
        setModalType(type);
    }, []);

    const hideModal = React.useCallback(() => {
        setModalType(null);
    }, []);

    const contextValue = React.useMemo(
        () => ({
            showModal,
            hideModal,
        }),
        [showModal, hideModal]
    );

    return (
        <ModalContext.Provider value={contextValue}>
            {modalType && <Modal modalType={modalType} hideModal={hideModal} />}
            {children}
        </ModalContext.Provider>
    );
}

export const useModal = (): ModalContextProps => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};
