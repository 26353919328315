import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { captureException, withScope } from '@sentry/react';
import { useAuth } from '../../contexts/AuthContext.tsx';

import { Page } from '../../components/common.tsx';
import { capitalizeFirstLetter } from '../../utils/string.helpers.ts';
import { onToContent } from '../../utils/navigate.helpers.ts';
import { Content } from '../../content/types.content.ts';
import { getRandomContentByInterestIds } from '../../content/contents.query.content.ts';
import { ModalType, useModal } from '../../contexts/ModalContext.tsx';

export function NookContentTitle(props: { content: Content }) {
    const { showModal } = useModal();
    const { userData } = useAuth();
    const { content } = props;
    const isUserPremium = userData?.subscriptionDetails.status === 'active';
    return (
        <Link
            to={onToContent(content.id)}
            onClick={(e) => {
                if (!isUserPremium && content.is_premium) {
                    e.preventDefault();
                    showModal(ModalType.GET_PREMIUM);
                }
            }}
        >
            <div className="flex p-2 bg-gray-100 items-center rounded">
                <div
                    className="h-16 w-16 overflow-hidden mr-2 rounded p-2"
                    style={{
                        backgroundColor: content.color,
                    }}
                >
                    <img alt="" src={content.image} />
                </div>
                <div className="flex flex-col justify-center">
                    <p className="font-semibold text-xs text-gray-400">
                        {capitalizeFirstLetter(content.type)}
                    </p>
                    <label className="text-md flex items-baseline">
                        {content.title}
                        {!isUserPremium && content.is_premium ? (
                            <FontAwesomeIcon icon={faLock} className="pl-2" />
                        ) : null}
                    </label>
                    <p className="font-semibold text-xs text-gray-400">
                        {content.minutes} mins
                    </p>
                </div>
            </div>
        </Link>
    );
}

function NookPage() {
    const { userData } = useAuth();
    const [content, setContent] = useState<Content[]>();

    useEffect(() => {
        async function setup() {
            try {
                if (userData?.interests) {
                    const newContent = await getRandomContentByInterestIds(
                        userData?.interests
                    );
                    setContent(newContent);
                }
            } catch (e) {
                withScope((scope) => {
                    scope.setExtra('uid', userData?.id);
                    scope.setExtra('action', 'load_content_on_nook_page');
                    captureException(e);
                });
            }
        }
        setup();
    }, [userData]);

    return (
        <Page>
            <h1 className="pb-2">Today&apos;s Nook</h1>
            <div>
                {content?.map((item) => (
                    <div key={item.id} className="pb-2">
                        <NookContentTitle content={item} />
                    </div>
                ))}
            </div>
        </Page>
    );
}

export default NookPage;
