import React from 'react';
import { Link } from 'react-router-dom';

import { Page } from '../../components/common.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';
import { useGetAllCategories } from '../../content/category.query.content.ts';
import { Category } from '../../content/types.content.ts';

function CategoryButton({ item }: { item: Category }) {
    const url = `${routeNames.explore}/${routeNames.category}/${item.id}`;
    return (
        <Link
            to={url}
            className="w-full py-5 md:py-6 px-3 rounded-lg text-left overflow-hidden relative flex items-center "
            style={{ backgroundColor: item.color, textDecoration: 'none' }}
        >
            <img
                src={item.image}
                alt=""
                className="absolute w-24"
                style={{
                    right: 10,
                }}
            />
            <label
                className="text-lg "
                style={{
                    color: item.font_color,
                }}
            >
                {item.title}
            </label>
        </Link>
    );
}

function ExplorePage() {
    const { data: categories, error, loading } = useGetAllCategories();

    return (
        <Page>
            <h1 className="pb-2">Explore</h1>
            {loading && <p>Loading...</p>}
            {error && <p className="text-red-500">error</p>}
            {categories &&
                categories.map((item) => (
                    <div className="pb-2" key={item.id}>
                        <CategoryButton item={item} />
                    </div>
                ))}
            <div className="pt-56" />
        </Page>
    );
}

export default ExplorePage;
