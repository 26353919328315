import { useEffect, useState } from 'react';
import { Category, Tables } from './types.content.ts';
import { SQLiteServiceInstance } from './db.content.ts';

export async function getAllCategories(): Promise<Category[]> {
    return SQLiteServiceInstance.then(
        (service) =>
            service.query(`SELECT * FROM ${Tables.categories}`) as Category[]
    );
}

export async function getCategoryById(id: number): Promise<Category | null> {
    return SQLiteServiceInstance.then(
        (service) =>
            service.query(`SELECT * FROM ${Tables.categories} WHERE id = ?`, [
                id,
            ])[0] as Category
    );
}

export function useGetAllCategories(): {
    loading: boolean;
    error: string | null;
    data: Category[];
} {
    const [data, setData] = useState<Category[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getAllCategories();
                setData(result as Category[]);
            } catch (e) {
                console.log(e);
                setError(e as string);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    return { loading, error, data };
}

export function useGetCategoryById(id: number): {
    loading: boolean;
    error: string | null;
    data: Category | null;
} {
    const [data, setData] = useState<Category | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getCategoryById(id);
                setData(result as Category);
            } catch (e) {
                console.log(e);
                setError(e as string);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);
    return { loading, error, data };
}
