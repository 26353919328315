import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { captureException, withScope } from '@sentry/react';
import LoadingOverlay from '../../components/LoadingOverlay.tsx';
import auth from '../../firebase/config.firbase.ts';
import { getUserData } from '../../queries/users.query.ts';
import { routeNames } from '../../navigation/types.navigation.ts';

function SubscribeProtectedRoute({ children }: PropsWithChildren) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthAndSubscription = async () => {
            // Check if user is authenticated
            onAuthStateChanged(auth, async (user) => {
                try {
                    if (user) {
                        const userData = await getUserData();
                        if (userData) {
                            if (!userData.hasSubscribed) {
                                console.log('navigte to subscription');
                                // User is not subscribed, redirect to the subscription page
                                navigate(routeNames.subscription);
                            } else {
                                // User is subscribed, allow access
                                setLoading(false);
                            }
                        }
                    }
                } catch (e) {
                    withScope((scope) => {
                        scope.setExtra('uid', user?.uid);
                        scope.setExtra(
                            'action',
                            'subscription_protected_route'
                        );
                        captureException(e);
                    });
                }
            });
        };

        checkAuthAndSubscription();
    }, [navigate]);

    if (loading) {
        return <LoadingOverlay isLoading={loading} />; // Show a loading indicator while checking
    }

    return <Outlet />; // Render the children (protected content) if checks pass
}

export default SubscribeProtectedRoute;
