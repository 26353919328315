import { useEffect, useState } from 'react';
import { SQLiteService } from './db.content.ts';
import { Content, Tables } from './types.content.ts';
import { useAuth } from '../contexts/AuthContext.tsx';

export async function getAllContents(): Promise<Content[]> {
    const dbService = await SQLiteService.getInstance();
    const result = dbService.query(`SELECT * FROM ${Tables.contents}`);
    return result as Content[];
}

export async function getContentById(id: number): Promise<Content | null> {
    const dbService = await SQLiteService.getInstance();
    const result = dbService.query(
        `SELECT * FROM ${Tables.contents} WHERE id = ?`,
        [id]
    );
    return result.length > 0 ? (result[0] as Content) : null;
}

export async function getContentByIds(ids: number[]): Promise<Content[]> {
    if (ids.length > 0) {
        const dbService = await SQLiteService.getInstance();

        const rowString = ids.join(',');
        const result = dbService.query(
            `SELECT * 
            FROM ${Tables.contents} 
            WHERE id 
            IN (${rowString});`
        );
        return result;
    }
    return [];
}

export async function getContentByInterestId(
    interestId: number
): Promise<Content[]> {
    const dbService = await SQLiteService.getInstance();
    const result = dbService.query(
        `SELECT * FROM ${Tables.contents} WHERE id IN (SELECT content_id FROM ${Tables.contents_interest_links} WHERE interest_id = ?)`,
        [interestId]
    );
    return result as Content[];
}

export async function getContentsByListId(listId: number): Promise<Content[]> {
    const dbService = await SQLiteService.getInstance();
    const result = dbService.query(
        `SELECT * FROM ${Tables.contents} WHERE id IN (SELECT content_id FROM ${Tables.components_content_list_content_lists_contents_links} WHERE content_list_id = ?)`,
        [listId]
    );
    return result as Content[];
}

export async function getRandomContentByInterestIds(
    interestIds: number[]
): Promise<Content[]> {
    const dbService = await SQLiteService.getInstance();
    const result = dbService.query(
        `SELECT * FROM ${Tables.contents} WHERE id IN (SELECT content_id FROM ${Tables.contents_interest_links} WHERE interest_id IN (${interestIds.join(
            ','
        )})) ORDER BY RANDOM() LIMIT 5`
    );
    return result as Content[];
}

export function useGetContentById(id?: number) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Content | null>(null);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        async function setup() {
            if (id) {
                const content = await getContentById(id);
                setData(content);
            } else {
                setError('Invalid Request');
            }
            setLoading(false);
        }
        setup();
    }, [id]);
    return { loading, data, error };
}

export function useGetFavouriteContent() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Content[] | null>(null);
    const [error, setError] = useState<string | null>(null);
    const { userData } = useAuth();

    useEffect(() => {
        async function setup() {
            if (userData) {
                if (userData.favouritedContent.length > 0) {
                    const content = await getContentByIds(
                        userData.favouritedContent
                    );
                    setData(content);
                }
            } else {
                setError('Invalid Request');
            }
            setLoading(false);
        }
        setup();
    }, [userData]);

    return { loading, data, error };
}
