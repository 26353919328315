// eslint-disable-next-line import/prefer-default-export

import { useEffect, useState } from 'react';
import { SQLiteServiceInstance } from './db.content.ts';
import { Category, Tables } from './types.content.ts';

export async function getCategoryById(id: number): Promise<Category | null> {
    return SQLiteServiceInstance.then(
        (service) =>
            service.query(`SELECT * FROM ${Tables.categories} WHERE id = ?`, [
                id,
            ])[0] as Category
    );
}

export interface ListContent {
    id: string;
    title: string;
    image: string;
    description: string;
    src: string;
    mediaCategory: 'meditation' | 'motivation';
    mediaType: 'audio' | 'video';
    weight: number;
    mediaLength: number;
    interests: string[];
    minutes: number;
    color?: string;
    is_premium: boolean;
}
interface QueryResult extends ListContent {
    content_list_id: number;
    content_list_title: string;
}
export interface ContentList {
    contentListId: number;
    contentListTitle: string;
    contents: ListContent[];
}

const groupContentByList = (queryResults: QueryResult[]): ContentList[] => {
    return queryResults.reduce((acc: ContentList[], item: QueryResult) => {
        // Find the content list or create a new one
        let list = acc.find((l) => l.contentListId === item.content_list_id);
        if (!list) {
            list = {
                contentListId: item.content_list_id,
                contentListTitle: item.content_list_title,
                contents: [],
            };
            acc.push(list);
        }

        // Add the content to the corresponding list
        const content = {} as ListContent;
        Object.keys(item)
            .filter(
                (key) =>
                    key !== 'content_list_id' && key !== 'content_list_title'
            )
            .forEach((key) => {
                // @ts-expect-error - This is a dynamic key
                content[key] = item[key];
            });
        list.contents.push(content);
        return acc;
    }, []);
};

export function getListsWithContentsByCategoryId(
    categoryId: number
): Promise<ContentList[]> {
    return SQLiteServiceInstance.then(async (service) => {
        const lists = (await service.query(
            `SELECT 
    cl.id AS content_list_id,
    cl.title AS content_list_title,
    c.id AS id,
    c.title AS title,
    c.color AS color,
    c.description AS description,
    c.type AS mediaType,
    c.audio AS src,
    c.image AS image,
    c.minutes AS minutes,
    c.is_premium AS is_premium
FROM categories AS cat
JOIN categories_components AS cc ON cat.id = cc.entity_id
JOIN components_content_list_content_lists AS cl ON cc.component_id = cl.id
JOIN components_content_list_content_lists_contents_links AS cll ON cl.id = cll.content_list_id
JOIN contents AS c ON cll.content_id = c.id
WHERE cat.id = ?;`,
            [categoryId]
        )) as QueryResult[];

        return groupContentByList(lists);
    });
}

export function useGetListsWithContentsByCategoryId(categoryId: number): {
    loading: boolean;
    error: string | null;
    data: ContentList[];
} {
    const [data, setData] = useState<ContentList[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result =
                    await getListsWithContentsByCategoryId(categoryId);
                setData(result as ContentList[]);
            } catch (e) {
                console.log(e);
                setError(e as string);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [categoryId]);
    return { loading, error, data };
}
