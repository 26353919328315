/* eslint-disable import/prefer-default-export */
import {
    arrayRemove,
    arrayUnion,
    doc,
    getDoc,
    setDoc,
    updateDoc,
} from 'firebase/firestore';
import { collections } from './types.query.ts';
import auth, { db } from '../firebase/config.firbase.ts';

export type UserData = {
    id: string;
    isOnboarded: boolean;
    favouritedContent: number[];
    interests: number[];
    hasSubscribed: boolean;
    subscriptionDetails: {
        id?: string;
        status: 'active' | 'inactive';
        type?: 'paypal' | 'stripe';
    };
};

export async function createUserData(
    uid: string,
    mailingList: boolean
): Promise<void> {
    return setDoc(doc(db, collections.users, uid), {
        isOnboarded: false,
        favouritedContent: [],
        interests: [],
        hasSubscribed: false,
        isPremium: false,
        agreeToTerms: true,
        subscriptionDetails: {
            status: 'inactive',
        },
        mailingList,
    });
}

export async function setUserDataInterests(
    uid: string,
    interests: number[]
): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        interests,
    });
}

export async function setUserDataHasOnboarded(uid: string): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        isOnboarded: true,
    });
}

export async function setUserDataHasSubscribed(uid: string): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        hasSubscribed: true,
        isPremium: true,
    });
}

export async function getUserData(): Promise<UserData | undefined> {
    if (auth.currentUser) {
        const userDocRef = doc(db, collections.users, auth.currentUser.uid);
        const userData = await getDoc(userDocRef);
        return {
            id: userData.id,
            ...userData.data(),
        } as UserData;
    }
    return undefined;
}

export async function addContentToFavourites(
    uid: string,
    contentId: number
): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        favouritedContent: arrayUnion(contentId),
    });
}

export async function removeContentToFavourites(
    uid: string,
    contentId: number
): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        favouritedContent: arrayRemove(contentId),
    });
}
