import React from 'react';

interface LoadingOverlayProps {
    isLoading: boolean;
}

function LoadingOverlay({ isLoading }: LoadingOverlayProps) {
    if (!isLoading) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="w-16 h-16 border-4 border-t-4 border-t-blue-500 rounded-full animate-spin" />
        </div>
    );
}

export default LoadingOverlay;
