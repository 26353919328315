import React from 'react';

import { Page } from '../../components/common.tsx';
import { useGetFavouriteContent } from '../../content/contents.query.content.ts';
import { NookContentTitle } from './NookPage.tsx';

function FavouritePage() {
    const { data } = useGetFavouriteContent();

    return (
        <Page>
            <h1 className="pb-2">Favourites</h1>
            <div>
                {data?.map((item) => (
                    <div key={item.id} className="pb-2">
                        <NookContentTitle content={item} />
                    </div>
                ))}
            </div>
        </Page>
    );
}

export default FavouritePage;
