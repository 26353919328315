export enum Tables {
    categories = 'categories',
    contents = 'contents',
    components_content_list_content_lists = 'components_content_list_content_lists',
    components_content_list_content_lists_contents_links = 'components_content_list_content_lists_contents_links',
    categories_components = 'categories_components',
    versions = 'versions',
    interests = 'interests',
    contents_interest_links = 'contents_interest_links',
}
// Table: categories
export type Category = {
    id: number;
    title: string;
    created_at: string; // Assuming datetime as string
    updated_at: string;
    published_at: string;
    created_by_id: number | null;
    updated_by_id: number | null;
    color: string;
    font_color: string;
    image: string;
};

// Table: contents
export type Content = {
    id: number;
    title: string;
    description: string;
    color: string;
    audio: string;
    type: string;
    image: string;
    created_at: string;
    updated_at: string;
    published_at: string;
    created_by_id: number | null;
    updated_by_id: number | null;
    minutes: number | null;
    is_premium: boolean;
};

// Table: components_content_list_content_lists
export type ContentListComponent = {
    id: number;
    title: string;
};

// Table: components_content_list_content_lists_contents_links
export type ContentListContentLink = {
    id: number;
    content_list_id: number;
    content_id: number;
    content_order: number;
};

// Table: categories_components
export type CategoryComponent = {
    id: number;
    entity_id: number;
    component_id: number;
    component_type: string;
    field: string;
    order: number;
};

// Table: versions
export type Version = {
    id: number;
    build: string;
    created_at: string;
    updated_at: string;
    published_at: string;
    created_by_id: number | null;
    updated_by_id: number | null;
};

// Table: interests
export type Interest = {
    id: number;
    title: string;
    created_at: string;
    updated_at: string;
    published_at: string;
    created_by_id: number | null;
    updated_by_id: number | null;
    weight: number | null;
};

// Table: contents_interest_links
export type ContentInterestLink = {
    id: number;
    content_id: number;
    interest_id: number;
    content_order: number;
};

export type List = {
    content: Content[];
};
