import React from 'react';
import {
    CustomerServiceOutlined,
    PlayCircleOutlined,
    SoundOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { shortenText } from '../utils/string.helpers.ts';
import { ContentList, ListContent } from '../content/lists.query.content.ts';
import { useAuth } from '../contexts/AuthContext.tsx';
import { ModalType, useModal } from '../contexts/ModalContext.tsx';

function selectIcon(type: 'audio' | 'video') {
    switch (type) {
        case 'audio':
            return <SoundOutlined />;
        case 'video':
            return <PlayCircleOutlined />;
        default:
            return <CustomerServiceOutlined />;
    }
}

export function ContentInfoItem(props: {
    content: ListContent;
    to: string;
    userIsPremium: boolean;
}) {
    const { content, to, userIsPremium } = props;
    const { showModal } = useModal();
    return (
        <Link
            to={to}
            onClick={(e) => {
                if (!userIsPremium && content.is_premium) {
                    e.preventDefault();
                    showModal(ModalType.GET_PREMIUM);
                }
            }}
        >
            <div
                className="rounded-lg  w-full  overflow-hidden bg"
                style={{
                    backgroundImage: `url(${content.image})`,
                    backgroundSize: '100px 100px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundColor: content.color || '#E4E7EB',
                    height: ' 30vw',
                    maxHeight: 200,
                }}
            >
                {/* <img src={content.image} alt="" /> */}
            </div>
            <p className="leading-5 pt-1">
                <strong>
                    {content.title}

                    {!userIsPremium && content.is_premium ? (
                        <FontAwesomeIcon icon={faLock} className="pl-2" />
                    ) : null}
                </strong>
            </p>
            <div className="flex flex-row">
                {selectIcon('audio')}
                <p className="font-semibold text-xs pl-1">
                    {content.mediaType}
                </p>
            </div>
            <p className="leading-4 text-sm">
                {shortenText(content.description || '', 30)}
            </p>
        </Link>
    );
}

export function ContentInfoList(props: {
    list: ContentList;
    onToHandler: (id: string) => string;
}) {
    const { list, onToHandler } = props;
    const { userData } = useAuth();
    const userIsPremium = userData?.subscriptionDetails.status === 'active';
    return (
        <div>
            <h3 className="pb-2">{list.contentListTitle}</h3>
            <div className="grid grid-cols-2 gap-3">
                {list.contents.map((item) => (
                    <ContentInfoItem
                        content={item}
                        key={item.id}
                        to={onToHandler(item.id)}
                        userIsPremium={userIsPremium}
                    />
                ))}
            </div>
        </div>
    );
}
