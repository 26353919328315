import React, { useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';
import { Button } from '../../components/common.tsx';
// import GoogleButton from '../../components/GoogleSignInButton.tsx';
const emails = [
    'hot_moms_hit_curbs@gmail.com',
    'imma_cool_mom@gmail.com',
    'caffiene_machine_247@gmail.com',
];

const mumFacts = [
    {
        title: 'Multitasking Superpowers',
        info: "Mums can process multiple tasks at once, often juggling them like pros. The 'mum brain' can handle many things, even if it sometimes forgets where the keys are!",
    },
    {
        title: 'Lost Sleep in the First Year',
        info: "Mums lose up to 44 days of sleep in the first year, getting about 2-3 hours less each night. That's 44 days worth of sleep gone!",
    },
    {
        title: "Universal 'Mum' Word",
        info: "Variations of 'mum,' 'mama,' or 'mom' are found across nearly all languages, including 'mama' in Italian, 'mamá' in Spanish, and 'maman' in French.",
    },
    {
        title: 'Calories in Baby Food',
        info: 'In just a year, a mum can produce over 50,000 calories worth of baby food through breast milk or formula feeds.',
    },
    {
        title: '300 Questions a Day',
        info: 'Kids ask mums around 300 questions a day, with four-year-olds leading the way with about 23 questions per hour!',
    },
    {
        title: 'Mum-Invented Everyday Items',
        info: 'From baby carriers to disposable diapers, mums have been the masterminds behind many everyday inventions.',
    },
    {
        title: 'Internal Alarm Clock',
        info: 'Many mums wake up minutes before their baby cries or their kids need them, thanks to a developed instinct and light sleep patterns.',
    },
    {
        title: 'Longest Recorded Pregnancy',
        info: 'The longest recorded pregnancy lasted 375 days, beating the typical 280-day average by nearly 100 days!',
    },
    {
        title: 'Walking Libraries',
        info: 'Mums spend about 8 hours each week answering questions and teaching life skills, becoming a never-ending source of knowledge.',
    },
    {
        title: 'Mum Brain Growth',
        info: "A mother's brain grows after birth, especially in areas related to empathy, anxiety, and social interaction, all part of bonding with her child.",
    },
];

// Function to randomly select one fact
function getRandomMumFact() {
    const randomIndex = Math.floor(Math.random() * mumFacts.length);
    return mumFacts[randomIndex];
}

function routeAwayHandler(email: string, route: string): string {
    const searchParams = new URL(`${window.location.origin}${route}`);
    searchParams.searchParams.append('email', email);
    return searchParams.href;
}

function Login() {
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get('email') || '');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const isMobilview = window.innerWidth < 768;

    const handleSubmit = async (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        try {
            await login(email, password);
            navigate(routeNames.nook);
        } catch (err) {
            setError('Failed to log in');
        }
        setLoading(false);
    };

    const emailIndex = useRef(Math.floor(Math.random() * emails.length));
    const randomfact = useRef(getRandomMumFact());
    if (!isMobilview) {
        return (
            <div className="flex w-full h-full">
                <div className="w-4/12 text-center m-auto">
                    {error && <p className="text-red-600 text-2xl">{error}</p>}
                    <h2 className="text-3xl mb-6">Welcome to Nook</h2>
                    <form
                        className="text-left w-9/12 mx-auto"
                        onSubmit={handleSubmit}
                    >
                        <div className="mt-2">
                            <label htmlFor="email" className="text-gray-500 ">
                                Email
                            </label>
                            <br />
                            <input
                                id="email"
                                data-testid="login-email-id"
                                required
                                className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50"
                                type="email"
                                placeholder={emails[emailIndex.current]}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="email"
                            />
                        </div>

                        <div className="mt-2 mb-4">
                            <label
                                htmlFor="password"
                                className="text-gray-500 "
                            >
                                Password
                            </label>
                            <br />
                            <input
                                id="password"
                                required
                                className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50 "
                                type="password"
                                placeholder="******"
                                value={password}
                                autoComplete="new-password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Link
                                to={routeAwayHandler(email, routeNames.forgot)}
                                className="underline text-button-blue"
                            >
                                Forgot your password?
                            </Link>
                        </div>

                        <Button disabled={loading} type="submit">
                            {loading ? 'Loading...' : 'Login'}
                        </Button>

                        <p className="text-center    pt-4 ">
                            Don&apos;t have an account?{' '}
                            <Link
                                to={routeAwayHandler(email, routeNames.signup)}
                                className="text-button-blue underline font-bold"
                            >
                                Signup here
                            </Link>
                        </p>
                    </form>
                </div>
                <div
                    className="w-8/12 p-20 h-full "
                    style={{ backgroundColor: '#8369FF' }}
                >
                    <img
                        src="/images/abstract_bg.png"
                        alt="Background"
                        className="h-5/6"
                    />
                    <div>
                        <h1 className=" text-white pb-4">
                            {randomfact.current.title}
                        </h1>

                        <h3 className="text-white">
                            {randomfact.current.info}
                        </h3>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="text-center  h-full  flex ">
            <div className="m-auto w-11/12">
                {error && <p className="text-red-600 text-2xl">{error}</p>}
                <h2 className="text-3xl mb-6">Welcome to Nook</h2>
                <form className="text-left  mx-auto" onSubmit={handleSubmit}>
                    <div className="mt-2">
                        <label htmlFor="email" className="text-gray-500 ">
                            Email
                        </label>
                        <br />
                        <input
                            id="email"
                            data-testid="login-email-id"
                            required
                            className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50"
                            type="email"
                            placeholder={emails[emailIndex.current]}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                        />
                    </div>

                    <div className="mt-2 mb-4">
                        <label htmlFor="password" className="text-gray-500 ">
                            Password
                        </label>
                        <br />
                        <input
                            id="password"
                            required
                            className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50 "
                            type="password"
                            placeholder="******"
                            value={password}
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Link
                            to={routeAwayHandler(email, routeNames.forgot)}
                            className="underline text-button-blue"
                        >
                            Forgot your password?
                        </Link>
                    </div>

                    <Button disabled={loading} type="submit">
                        {loading ? 'Loading...' : 'Login'}
                    </Button>

                    <p className="text-center    pt-4 ">
                        Don&apos;t have an account?{' '}
                        <Link
                            to={routeAwayHandler(email, routeNames.signup)}
                            className="text-button-blue underline font-bold"
                        >
                            Signup here
                        </Link>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default Login;
