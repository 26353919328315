/* eslint-disable prettier/prettier */
import React, { ReactNode } from 'react';
import { Outlet, useLocation, Link } from 'react-router-dom';
import {
    ClockCircleOutlined,
    HeartOutlined,
    HomeOutlined,
    SearchOutlined,
    UserOutlined,
} from '@ant-design/icons';
import logo from '../assets/logo.png';
import { useAuth } from '../contexts/AuthContext.tsx';
import MusicPlayerBar from './MusicPlayerBar.tsx';
import { routeNames } from '../navigation/types.navigation.ts';
import { Button } from './common.tsx';

function PageLayout() {
    const { pathname } = useLocation();
    const { user, logout } = useAuth();
    const menuItems: {
        label: string;
        path: routeNames;
        icon: ReactNode;
    }[] = [
            {
                label: 'Nook',
                path: routeNames.nook,
                icon: <HomeOutlined className="text-3xl" />,
            },
            {
                label: 'Explore',
                icon: <SearchOutlined className="text-3xl" />,
                path: routeNames.explore,
            },
            {
                label: 'Account',
                path: routeNames.account,
                icon: <UserOutlined className="text-3xl" />,
            },
            {
                label: 'Favourites',
                path: routeNames.favourite,
                icon: <HeartOutlined className="text-3xl" />,
            },
        ];

    if (
        pathname.includes(routeNames.signup) ||
        pathname.includes(routeNames.login) || pathname.includes(routeNames.forgot)
    ) {
        return <Outlet />;
    }

    if (pathname.includes(routeNames.subscription)) {
        return (
            <div className="flex flex-col h-full">
                <div className="flex items-center p-2 px-4 border justify-between ">
                    <div className="flex flex-row items-baseline  bg-white  ">
                        <img src={logo} alt="Nook Logo" className="size-5" />
                        <p className="text-3xl font-bold pl-2 leading-snug  text-button-blue">
                            Nook
                        </p>
                    </div>
                    <div>
                        {pathname.includes(routeNames.subscription) && (
                            <Button onClick={logout}>Logout</Button>
                        )}
                    </div>
                    {user &&
                        !pathname.includes(routeNames.onboard) &&
                        !pathname.includes(routeNames.subscription) && (
                            <div className="w-72 flex justify-around max-md:hidden pl-10 m-auto ">
                                {menuItems.map((item) => (
                                    <Link
                                        aria-label={item.label}
                                        to={item.path}
                                        key={item.label}
                                        className={`flex-col flex items-center py-3 ${pathname === item.path ? 'text-black' : 'text-gray-400'}`}
                                    >
                                        {item.icon}
                                    </Link>
                                ))}
                            </div>
                        )}

                </div>
                <div className="flex-1">
                    <Outlet />
                </div>
            </div>
        );
    }

    const withinFullApp =
        user &&
        !pathname.includes(routeNames.onboard) &&
        !pathname.includes(routeNames.subscription);

    return (
        <div className="flex flex-col flex-1 h-full">
            <div className="flex items-center p-2 px-4 border justify-between ">
                <div className="flex flex-row items-baseline  bg-white  ">
                    <img src={logo} alt="Nook Logo" className="size-5" />
                    <p className="text-3xl font-bold pl-2 leading-snug  text-button-blue">
                        Nook
                    </p>
                </div>
                <div>
                    {pathname.includes(routeNames.subscription) && (
                        <Button onClick={logout}>Logout</Button>
                    )}
                </div>
                {withinFullApp && (
                    <div className="w-72 flex justify-around max-md:hidden pl-10 m-auto ">
                        {menuItems.map((item) => (
                            <Link
                                aria-label={item.label}
                                to={item.path}
                                key={item.label}
                                className={`flex-col flex items-center py-3 ${pathname === item.path ? 'text-black' : 'text-gray-400'}`}
                            >
                                {item.icon}
                            </Link>
                        ))}
                    </div>
                )}

                {
                    withinFullApp &&
                    <div>
                        <Link
                            to={routeNames.history}
                            className={`flex-col flex items-center py-3 ${pathname === routeNames.history ? 'text-black' : 'text-gray-400'}`}
                        >
                            <ClockCircleOutlined className='md:text-3xl text-2xl' />
                        </Link>
                    </div>}
            </div>
            <div
                className={`overflow-y-auto w-full h-full pb-16 ${pathname.includes(routeNames.signup) ? 'max-w-screen-lg' : 'max-w-screen-md'} md:m-auto `}
            >
                <Outlet />
            </div>
            {user &&
                !pathname.includes(routeNames.onboard) &&
                !pathname.includes(routeNames.subscription) &&
                !pathname.includes(routeNames.account_interests) && (
                    <div
                        className="absolute  w-full flex justify-center"
                        style={{ bottom: '2em' }}
                    >
                        <div className="flex justify-center w-8/12 max-w-screen-lg max-md:hidden">
                            <MusicPlayerBar />
                        </div>
                    </div>
                )}
            {user &&
                !pathname.includes(routeNames.onboard) &&
                !pathname.includes(routeNames.subscription) && (
                    <div
                        className="rounded-md  border border-b-0  justify-around flex w-full md:hidden fixed bottom-0 bg-white h-16"
                        style={{ zIndex: 999 }}
                    >
                        <div
                            className="absolute  flex justify-cente w-11/12"
                            style={{ top: '-4em' }}
                        >
                            <MusicPlayerBar />
                        </div>

                        {menuItems.map((item) => (
                            <Link
                                aria-label={item.label}
                                to={item.path}
                                key={item.label}
                                // className={`flex-col flex items-center py-3 `}
                                className={`flex-col flex items-center py-3 ${pathname.includes(item.path) ? 'text-black' : 'text-gray-400'}`}
                            >
                                {item.icon}
                                <label
                                    className={`text-sm ${pathname.includes(item.path) ? 'text-black' : 'text-gray-400'}`}
                                >
                                    {item.label}
                                </label>
                            </Link>
                        ))}
                    </div>
                )}
        </div>
    );
}

export default PageLayout;
