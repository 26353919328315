/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getAuth } from 'firebase/auth';

export async function cancelSubscription() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        console.log('User not logged in');
        return;
    }

    // Get the user's Firebase Auth ID token
    const idToken = await user.getIdToken();

    // Send the POST request to the Firebase Function
    const response = await axios.post(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/api/paypal/subscription/cancel`,
        {},
        {
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            },
        }
    );

    if (response.status !== 200) {
        const errorText = await response.data;
        throw new Error(`Error: ${errorText}`);
    }

    console.log('Subscription canceled successfully');
}

export async function deleteAccount() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        return;
    }

    // Get the user's Firebase Auth ID token
    const idToken = await user.getIdToken();

    // Send the POST request to the Firebase Function
    const response = await axios.post(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/api/user/event/delete`,
        {},
        {
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            },
        }
    );

    if (response.status !== 200) {
        const errorText = await response.data;
        throw new Error(`Error: ${errorText}`);
    }
    console.log('Account deleted successfully');
}
