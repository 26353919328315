import React, { useEffect, useState } from 'react';

import { captureException, withScope } from '@sentry/react';
import { Page } from '../../components/common.tsx';
import { getContentByIds } from '../../content/contents.query.content.ts';
import { NookContentTitle } from './NookPage.tsx';
import { Content } from '../../content/types.content.ts';

function getHistory(): number[] {
    const JSONhistory = localStorage.getItem('content_history') || '[]';
    try {
        const history = JSON.parse(JSONhistory);

        return Array.isArray(history) ? history : [];
    } catch (e) {
        return [];
    }
}

export function setHistory(id: number) {
    const history = getHistory();
    const lastInput = history[history.length - 1];
    if (lastInput === id) {
        return;
    }
    history.push(id);
    try {
        localStorage.setItem('content_history', JSON.stringify(history));
    } catch (e) {
        withScope((scope) => {
            scope.setExtra('content_id', id);
            scope.setExtra('action', 'save_histroy_to_local');
            captureException(e);
        });
    }
}

function HistoryPage() {
    const [content, setContent] = useState<Content[]>([]);

    useEffect(() => {
        getContentByIds(getHistory()).then((data) => {
            setContent(data);
        });
    }, []);

    return (
        <Page>
            <h1 className="pb-2">History</h1>
            <div>
                {content?.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`${item.id}_${index}`} className="pb-2">
                        <NookContentTitle content={item} />
                    </div>
                ))}
                {content.length === 0 && (
                    <p>
                        You have not played any media yet or are using a new
                        device
                    </p>
                )}
            </div>
        </Page>
    );
}

export default HistoryPage;
