/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-shadow
export enum routeNames {
    login = '/login',
    terms = '/terms',
    forgot = '/forgot',
    signup = '/signup',
    nook = '/nook',
    explore = '/explore',
    account = '/account',
    category = 'category',
    play = '/play',
    settings = 'settings',
    contact = 'contact',
    interests = 'interests',
    onboard = '/onboard',
    favourite = '/favourite',
    history = '/history',
    subscription = '/subscription',
    account_settings_password = `${routeNames.account}/${settings}/password`,
    account_settings = `${routeNames.account}/${settings}`,
    account_interests = `${routeNames.account}/${interests}`,
    account_contact = `${routeNames.account}/${contact}`,
}
