import React, { useState } from 'react';
import { MailOutlined } from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Page } from '../../components/common.tsx';

function ContactPage() {
    const [showPill, setShowPill] = useState(false);
    const navigate = useNavigate();
    const onCopyHandler = () => {
        navigator.clipboard.writeText('support@mmum.com.au');
        setShowPill(true);
        setTimeout(() => setShowPill(false), 3000);
    };

    return (
        <Page>
            <div className="flex pb-2">
                <Link
                    to=".."
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}
                    className="mr-2"
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                </Link>
                <h1>Contact Us</h1>
            </div>
            <div className="pb-2">
                <label className="text-lg">support@mmum.com.au</label>
                <Button onClick={onCopyHandler}>Copy</Button>
            </div>
            <Button onClick={() => window.open('mailto:support@mmum.com.au')}>
                <MailOutlined /> Email Us
            </Button>
            {showPill && (
                <div className="fixed top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-full z-50">
                    support@mmum.com.au to clipboard!
                </div>
            )}
        </Page>
    );
}

export default ContactPage;
