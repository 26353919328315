import React, {
    createContext,
    useState,
    useContext,
    useRef,
    PropsWithChildren,
    useMemo,
    useEffect,
} from 'react';
import { ListContent } from '../content/lists.query.content.ts';
import { Content } from '../content/types.content.ts';
import { setHistory } from '../pages/authenticated/HistoryPage.tsx';

// Define the types for the context
interface AudioContextType {
    isPlaying: boolean;
    playAudio: (content: ListContent) => void;
    pauseAudio: () => void;
    stopAudio: () => void;
    currentAudioUrl: string | null;
    content: ListContent;
    audioLoading: boolean;
    audioRef: HTMLAudioElement;
}

// Create the context
const AudioContext = createContext<AudioContextType | undefined>(undefined);

// Custom hook to use the Audio context
export const useAudio = (): AudioContextType => {
    const context = useContext(AudioContext);
    if (!context) {
        throw new Error('useAudio must be used within an AudioProvider');
    }
    return context;
};

function setNowPlayingLocalStorage(content: Content) {
    localStorage.setItem('nowPlaying', JSON.stringify(content));
}

function getNowPlayingLocalStorage(): Content | null {
    const content = localStorage.getItem('nowPlaying');
    if (!content) {
        return null;
    }
    return JSON.parse(content) as Content;
}

// Create the provider component
export function AudioProvider({ children }: PropsWithChildren) {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [currentAudioUrl, setCurrentAudioUrl] = useState<string | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [content, setContent] = useState<Content | null>(null);
    const [audioLoading, setAudioLoading] = useState<boolean>(false);

    useEffect(() => {
        setContent(getNowPlayingLocalStorage());
    }, []);

    // Play audio function
    const playAudio = async (newContent: Content) => {
        setHistory(newContent.id);
        setNowPlayingLocalStorage(newContent);
        // If the audio is already playing and the URL is the same, resume playback
        try {
            if (audioRef.current && newContent.audio === currentAudioUrl) {
                await audioRef.current.play();
                setIsPlaying(true);
                return;
            }

            // If a different audio is playing, stop it
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                localStorage.setItem('audioPlaybackTime', '0');
            }

            // Set the new audio URL and play it
            const newAudio = new Audio(newContent.audio);
            audioRef.current = newAudio;
            setCurrentAudioUrl(newContent.audio);
            setContent(newContent);

            setAudioLoading(true);
            await newAudio.play();
            setAudioLoading(false);

            setIsPlaying(true);

            // Add event listener to update state when audio ends
            newAudio.addEventListener('ended', () => {
                setIsPlaying(false);
            });
        } catch (e) {
            console.log(e);
        }
    };

    // Pause audio function
    const pauseAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    // Stop audio function
    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current?.pause();
            audioRef.current.currentTime = 0;
        }
        localStorage.removeItem('audioPlaybackTime');
        localStorage.removeItem('nowPlaying');
        setIsPlaying(false);
        setCurrentAudioUrl(null);
    };

    useEffect(() => {
        const handleTimeUpdate = (e: any) => {
            if (audioRef.current) {
                const { currentTime } = audioRef.current;
                localStorage.setItem(
                    'audioPlaybackTime',
                    currentTime.toString()
                );
            }
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener(
                    'timeupdate',
                    handleTimeUpdate
                );
            }
        };
    }, [audioRef.current]);

    useEffect(() => {
        const savedTime = localStorage.getItem('audioPlaybackTime');
        if (savedTime && audioRef.current) {
            audioRef.current.currentTime = parseFloat(savedTime); // Restore playback time
        }
    }, [audioRef.current]);

    useEffect(() => {
        const handleAudioEnded = () => {
            localStorage.removeItem('audioPlaybackTime');
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('ended', handleAudioEnded);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('ended', handleAudioEnded);
            }
        };
    }, [audioRef.current]);

    const values = useMemo(
        () => ({
            isPlaying,
            playAudio,
            pauseAudio,
            stopAudio,
            currentAudioUrl,
            content,
            audioLoading,
            audioRef: audioRef.current,
        }),
        [currentAudioUrl, isPlaying, playAudio, content, audioLoading]
    );

    return (
        <AudioContext.Provider value={values}>{children}</AudioContext.Provider>
    );
}
